console.log('加载了Util')
//在后续的wps版本中，wps的所有枚举值都会通过wps.Enum对象来自动支持，现阶段先人工定义
var WPS_Enum = {
  msoCTPDockPositionLeft: 0,
  msoCTPDockPositionRight: 2,
}

/**
 * 通过wps提供的接口执行一段脚本
 * @param {*} param 需要执行的脚本
 */
function GetUrlPath() {
  let e = document.location.toString()
  e = decodeURI(e)
  if (-1 !== e.indexOf('/')) e = e.substring(0, e.lastIndexOf('/'))
  return e
}

/**
 * 获取当前环境的项目网址
 */
export const getLocationOrigin = () => {
  return  window.location.origin + (process.env.PUBLIC_URL  || '')
}

export const judgingCharacters = (str, max) => {
  let num = 0
  for (let i = 0; i < str?.length; i++) {
    let a = str[i]
    const reg = /[\u4e00-\u9fa5]/g
    if (a.match(reg)) {
      num += 2
    } else {
      num += 1
    }
    if (num > max) {
      return false
    }
  }
  return true
}

//js动态修改浏览器ico图标
export const change_icon = (iconUrl) => {
  let $favicon = document.querySelector('link[rel="icon"]');
  if ($favicon !== null) {
      $favicon.href = iconUrl;
  } else {
      $favicon = document.createElement("link");
      $favicon.rel = "icon";
      $favicon.href = iconUrl;
      document.head.appendChild($favicon);
  }
}
